@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'ss03' 1, 'ss01' 1, 'liga' 0;
  font-family: 'Satoshi',  sans-serif;
}

html,
body,
#root {
  height: 100%;
}

code,
kbd,
samp,
pre {
  font-feature-settings: 'ss03' 1, 'ss01' 1, 'liga' 0;
  font-family: 'Satoshi',  sans-serif;
}

:focus {
  outline: none;
}
.yocket-btn {
  @apply rounded-lg px-5 py-2.5 bg-yocket-orange-700 text-yocket-neutral-0 cursor-pointer text-sm font-bold
  hover:bg-yocket-orange-800 focus-within:outline-0 focus-within:ring-2 focus-within:ring-yocket-orange-400
  focus-within:ring-offset-2 focus-within:ring-offset-white disabled:cursor-not-allowed
  disabled:bg-yocket-neutral-100 disabled:text-yocket-neutral-400 disabled:shadow-none
  shadow-[0_2px_8px_-2px_#F2B151,_inset_0_-2px_1px_rgba(55,_65,_81,_0.15)];
}

.Toastify__toast-container, .Toastify__toast {
  background: transparent !important;
  box-shadow: none !important;
}

@media screen and (max-width: 600px) {
  .Toastify__toast-container {
    background-color: transparent;
    max-width: 100vw;
  }
  .Toastify__toast-body, .Toastify__toast-body > div {
    width: 100%;
  }
  div.Toastify__toast-icon {
    width: auto;
  }
  div.Toastify__toast {
    width: fit-content !important;
    margin-left: auto;
    margin-right: auto;
  }
}

/*
* Yocket scroll bar design start
*/
.yocket-scrollbar {
      scrollbar-color: #CED0D9 transparent;
      scrollbar-width: thin;
  }
  .yocket-scrollbar:hover {
      scrollbar-color: #8C93A1 transparent;
  }

  .yocket-scrollbar::-webkit-scrollbar {
      @apply w-1.5 bg-transparent rounded-full;
  }

  .yocket-scrollbar::-webkit-scrollbar-button {
      @apply hidden;
  }

  .yocket-scrollbar::-webkit-scrollbar-track {
      @apply bg-transparent;
  }

  .yocket-scrollbar::-webkit-scrollbar-thumb {
      @apply bg-yocket-neutral-300 hover:bg-yocket-neutral-400 rounded-full cursor-pointer
  }
  /*
   * Yocket scroll bar design end
   */


   .dynamic-height-main-div {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}